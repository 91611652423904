<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="addDataDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center py-4>
          <v-flex xs12 lg10>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout wrap justify-start>
                <v-flex xs12 pl-2 py-5>
                  <v-combobox
                    v-model="tag"
                    :items="tagItems"
                    chips
                    clearable
                    label="Tag"
                    multiple
                    class="pt-3"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="remove(item, tag)"
                      >
                        <strong>{{ item }}</strong
                        >&nbsp;
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-center>
                <v-flex xs6 sm4 xl3 px-2>
                  <v-btn
                    block
                    tile
                    outlined
                    color="#FF1313"
                    light
                    :ripple="false"
                    depressed
                    @click="addDataDialog = false"
                    class="itemValue"
                  >
                    Cancel
                  </v-btn>
                </v-flex>
                <v-flex xs6 sm4 xl3 px-2 v-if="edit == false">
                  <v-btn
                    block
                    tile
                    color="#000"
                    light
                    :ripple="false"
                    depressed
                    :disabled="!valid"
                    @click="validate"
                    class="itemValue"
                  >
                    <!-- <span style="color: #fff" v-if="storage">Edit {{ dataType }}</span> -->
                    <span style="color: #fff">SAVE</span>
                  </v-btn>
                </v-flex>
                <v-flex xs6 sm4 xl3 px-2 v-if="edit == true">
                  <v-btn
                    block
                    tile
                    color="#000"
                    light
                    :ripple="false"
                    depressed
                    :disabled="!valid"
                    @click="validateEdit"
                    class="itemValue"
                  >
                    <!-- <span style="color: #fff" v-if="storage">Edit {{ dataType }}</span> -->
                    <span style="color: #fff">EDIT</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialogue" max-width="600px">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
              >Do you want to delete this tags ?</span
            >
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              class="itemValue"
              @click="deleteData()"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start pt-3 pl-2 pr-5 px-6>
      <v-flex align-self-center xs12 sm10 md10 lg10 xl10 px-5 text-left>
        <span
          style="color: black; font-weight: bold; font-size: 18px"
          class="itemHeading1"
        >
          Tags</span
        >
      </v-flex>

      <v-flex align-self-end xs6 sm2 md2 lg2 xl2 px-3>
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          @click="addDataDialog = true"
          class="itemValue"
        >
          Add Tag
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-5 pa-5>
      <v-flex v-for="(item, i) in tags" :key="i" pa-2 pt-5>
        <v-chip color="red" close @click:close="removeItem(item)">
          <strong
            ><span style="color: white">{{ item.tag }}</span></strong
          >&nbsp;
        </v-chip>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      deleteDialogue: false,
      banDialogue: false,
      activateDialogue: false,
      tags: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      tag: [],
      addDataDialog: false,
      valid: true,
      edit: false,
      editId: "",
      tagItems: [],
      itemId: "",
      rules: {
        required: (value) => !!value || " Required Field",
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    remove(i, tag) {
      const index = tag.indexOf(i);
      if (index > -1) {
        tag.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/tag/List",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.tags = response.data.data;
              this.pinName = response.data.name;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) this.addData();
    },
    validateEdit() {
      if (this.$refs.form.validate()) this.updateData();
    },
    addData() {
      axios({
        method: "post",
        url: "/tag/Add",
        data: {
          tag: this.tag,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.addDataDialog = false;
            this.showSnackBar = true;
            this.msg = "Added Successfully";
            this.getData();
            (this.name = ""), (this.pincodes = "");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    updateData() {
      axios({
        method: "post",
        url: "/tag/Edit",
        data: {
          id: this.editId,
          name: this.name,
          pincodes: this.pincodes,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.addDataDialog = false;
            this.showSnackBar = true;
            this.msg = "Updated Successfully";
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    editDialog(item) {
      this.edit = true;
      this.editing = item;
      this.addDataDialog = true;
      this.name = item.name;
      this.pincodes = item.pincodes;
      this.editId = item._id;
    },
    removeItem(item) {
      this.deleteDialogue = true;
      this.itemId = item;
    },
    deleteData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/tag/Delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.itemId._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
